:root {
    --primary-color: #121212;
    --secondary-color: #777777;
    --background-color: #FFFFFF;
    --inverted-color: #FFFFFF;
    --light-color: #DDDDDD;
    --highlight-color: #5289A5;
    --gray-color: #EEEEEE;
    --image-background: #FFFFFF;

    --content-width: 100%;
    --view-port-width: 96%;
    --logo-size: 90px;
}

@media (min-width: 810px) {
    :root {
        --content-width: 800px;   
        --view-port-width: 800px;
    }
}

@media (max-width: 810px) {
    .non-mobile {
        display: none;
    }
    .mobile {
        display: block !important;
    }
    :root {
        --logo-size: 50px;
    }
    .flex-row.flex-mobile-column {
        flex-direction: column !important;
    }
}

body {
    margin: 0;
    min-width: 100%;
    font-family: "Source Code Pro", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--background-color);
    color: var(--primary-color);
    margin: auto;
    display: flex;
    flex-direction: column;
    font-size: normal;
}

a {
    color: var(--primary-color);
    text-decoration: none;
}

code {
    font-family: "Source Code Pro", source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media print {
    :root {
        --primary-color: #000000 !important;
        --secondary-color: #000000 !important;
        --background-color: #FFFFFF !important;
        --inverted-color: #FFFFFF !important;
        --light-color: #DDDDDD !important;
        --highlight-color: #000000 !important;
        --gray-color: #FFFFFF !important;
        --image-background: #FFFFFF !important;
    }

    @page {
        size: A4;
        margin: 20mm 17mm 17mm 17mm;
    }

    html, body { 
        width: 210mm;
        height: 297mm;
    }

    .header {
        page-break-after: avoid;
    }

    .section {
        page-break-inside: avoid;;
    }

    .header {
        text-transform: capitalize !important;
    }
    .content-period {
        flex: 0 0 100px !important;
    }
    .non-print {
        display: none !important;
    }
    div.print-header {
        position: fixed;
        margin-top: -60px;
        top: 0;
    }
}

@media screen {
    div.print-header {
        display: none;
    }
}

html {
    scroll-behavior: smooth;
}

.secondary-color {
    color: var(--secondary-color);
}

.pt-1 { 
    padding-top: 1px;
}

.pt-20 { 
    padding-top: 20px;
}

.pt-40 { 
    padding-top: 40px;
}

.pl-20 {
    padding-left: 20px;
}

.pb-10 {
    padding-bottom: 10px;
}

.bg-gray {
    background-color: var(--gray-color);
}

.section {
    padding-bottom: 40px;
    padding-top: 70px;
    margin-top: -40px;
    width: var(--view-port-width);
    margin: auto;
}
.section.rows {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.section .row {
    flex-grow: 4;
}

.col {
    display: flex;
}

.align-right {
    margin-left: auto;
}
.section .header {
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: x-large;
    color: var(--highlight-color);
}

.nav svg, .header img, .header svg {
    fill: var(--highlight-color);
    stroke: var(--highlight-color);
    margin-right: 10px;
    height: 20px;
    width: 20px;
}

.nav svg {
    fill: var(--primary-color);
    border: none;
    cursor: pointer;
}

.content {
    ul {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}
.content-period {
    flex: 0 0 calc(var(--logo-size) + 50px);
}
.text-thin {
    font-weight: 300;
}
.text-bold {
    font-weight: 600;
}
.text-large {
    font-weight: 400;
    font-size: x-large;
}

.align-center {
    text-align: center;
}

.align-right {
    margin-left: auto;
}

.flex-row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.flex-row.space-between {
    justify-content: space-between;
}

.flex-row.space-around {
    justify-content: space-around;
}

.flex-row.space-center {
    justify-content: center;
}

.flex-row.center-items {
  align-items: center;
}

.flex-row div {
    text-wrap: avoid;
}

.mobile {
    display: none;
}