.label {
    height: 1.2rem;
    line-height: 1.2rem;
    margin: 4px;
    border-radius: 6px;
    background-color: var(--light-color);
    border: 2px solid var(--light-color); 
    padding: 1px 5px 1px 5px;
    margin-right: 9px;
    font-size: smaller;
    white-space: break-spaces;
}

.bg-gray .label {
    border: 1px solid var(--light-color); 
    background-color: transparent;
}