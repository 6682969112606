$vcard-size: 100px;

.vcard svg {
  fill: var(--primary-color);
  color: var(--highlight-color);
  width: $vcard-size;
  height: $vcard-size;
}

.vcard svg:hover {
  cursor: pointer;
  color: var(--primary-color);
}

.contact-item {
  text-align: center;
  margin: 20px;
  line-height: 1.4rem;

  span {
    color: var(--secondary-color);
    font-size: smaller;
  }

  a {
    cursor: pointer;
    color: var(--highlight-color);
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
}

.links {
  cursor: pointer; 
  svg {
    width: 25px;
    height: 25px;
    fill: var(--secondary-color);
    color: var(--secondary-color);
  }

  :hover {
    svg {
      fill: var(--primary-color);
      color: var(--primary-color);
    }
  }
}