.head {
    width: 200px;
    height: 200px;
    transition: border-radius 1s;
    border-radius: 100px;
    border: 1px solid var(--primary-color);
}

.name {
    padding-left: 20px;
    font-size: 1.2em;
    font-weight: 260;
    .title {
        text-transform: lowercase;
        letter-spacing: 0.2rem;
        font-size: 1.8em;
        line-height: 1.5em;
        font-weight: 100;
    }
}