.company-logo {
    width: var(--logo-size);
    height: var(--logo-size);
    display: block;
    margin-top: 10px;
    padding: 10px;
}

.company-logo-background {
    background-color: var(--image-background);
    border-radius: 10px;
}