.nav {
    display: flex;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 2;
    width: 100%;
    height: 60px;
    min-height: 60px;
    background-color: var(--background-color);
    padding: 0;
    box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 11%);

    svg {
      color: var(--primary-color);
      fill: var(--primary-color);
      stroke: var(--primary-color);
    }

    svg:hover {
      color: var(--highlight-color);
      stroke: var(--highlight-color);
      fill: var(--highlight-color);
    }
}

.nav__container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: var(--content-width);
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.nav__items {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 0.9rem;
  gap: 2rem;
  margin: auto;
  width: var(--view-port-width);
  line-height: 1.4rem;
}

.active {
  font-weight: bold;
}

.headline {
  a {
    letter-spacing: 0.16rem;
    font-size: 1.4rem;
    text-decoration: none !important;
    font-weight: 200;
    display: flex;
    line-height: 46px;
    svg {
      width: 40px;
      height: 40px;
      padding: 2px;
      padding-right: 10px;
      border-right: 2px solid var(--primary-color);
    }
    strong {
      font-weight: 800;
    }

    &:hover {
      color: var(--highlight-color);
      svg {
        stroke: var(--highlight-color);
      }
  }
  }
}

.nav__container {
  width: var(--view-port-width);
}

.bm-menu, .nav__container, .nav__items {
  a {
    cursor: pointer;
  }
  
  a:hover {
    text-decoration: underline;
  }
}

.mobile-menu {
  padding: 5px;
}
  
/* Position and sizing of burger button */
.bm-burger-button {
  position: relative;
  width: 36px;
  height: 30px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 36px !important;
  width: 36px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: none;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  transform: translate3d(0,0,0);
  top: 0;
  left: 25%;
  height: 100%;
  width: 75% !important;
  overflow: auto;
  box-shadow: 0 0.125rem 0.5rem 0 rgb(0 0 0 / 11%);
  
  .menu-item, .menu-item:hover {
    padding: 10px;
  }

  svg {
    padding-right: 10px;
    color: var(--primary-color);
    fill: var(--primary-color);
    stroke: var(--primary-color);
  }
}

/* General sidebar styles */
.bm-menu {
  fill: var(--background-color);
  font-size: 1.15em;
  background-color: var(--background-color);
  padding: 2.5em 0 0;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: var(--background-color);
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  overflow: auto;
  height: 100%;
  position: fixed;
  left: 0;
  width: 100% !important;
  background: var(--background-color) !important;
}