.section[name="footer"] {
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: smaller;

  line-height: 1.2em;

  a:hover {
    text-decoration: underline;
  }

  span.separator {
    padding-left: 20px;
    padding-right: 20px;
  }

  svg {
    color: var(--primary-color);
    stroke: var(--primary-color);
    fill: var(--primary-color);
    width: 12px;
    height:12px;
    margin-right: 5px;
  }
}
